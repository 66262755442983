import React from "react"

import Typography from "@material-ui/core/Typography"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import CircledPicture from "./circledPicture"

import useStyles from "./articleItem.style"
import Link from "./Link"
import { getImage } from "../../helpers"

function ArticleItem({ data, picture }) {
  const classes = useStyles()

  if (!data || !data.title) return null
  const { author, content, title, createdAt, category, href, coverPicture } =
    data || {}

  return (
    <Link className={classes.card} href={href} style={{ color: "initial" }}>
      <div className={classes.contentWrapper}>
        {coverPicture && (
          <img
            className={classes.picture}
            src={getImage(coverPicture.file.url)}
            alt="article related illustration"
          />
        )}

        {category && (
          <Typography
            className={classes.category}
            variant="body1"
            color="primary"
          >
            {category.name}
          </Typography>
        )}

        <Typography className={classes.title} variant="h4" color="initial">
          {title}
        </Typography>

        <div className={classes.textWrapper}>
          <Typography className={classes.text} variant="body2" color="initial">
            {content &&
              documentToReactComponents(content.json, {
                renderNode: {
                  [BLOCKS.HEADING_2]: (_node, children) => (
                    <Typography
                      className={classes.subtitle}
                      variant="h4"
                      color="initial"
                    >
                      {children}
                    </Typography>
                  ),
                  [BLOCKS.EMBEDDED_ASSET]: node => (
                    <img
                      src={`${node.data.target.fields.file["en-US"].url}?w=300&q=90`}
                      alt={node.data.target.fields.title["en-US"]}
                    />
                  ),
                },
              })}
          </Typography>
          <div className={classes.textOverlay} />
        </div>

        <div className={classes.authorWrapper}>
          {/* <CircledPicture size={32} bordered /> */}
          {author && (
            <Typography
              className={classes.auhorName}
              variant="caption"
              color="initial"
            >
              {author.name}
            </Typography>
          )}
          <Typography
            className={classes.readingTime}
            variant="body1"
            color="initial"
            align="right"
          >
            {createdAt}
          </Typography>
        </div>
      </div>
    </Link>
  )
}

export default ArticleItem
