import React from "react"

import Typography from "@material-ui/core/Typography"

import CircledPicture from "./circledPicture"

import useStyles from "./testimonialCard.style"

export default function TestimonialCard({ testimony }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <CircledPicture
          className={classes.picture}
          size={44}
          bordered
          picture={testimony.picture}
        />
        <div>
          <Typography variant="button" color="initial">
            {testimony.name}
          </Typography>
          <Typography variant="body2" color="initial">
            {testimony.memberSince}
          </Typography>
        </div>
      </div>
      <Typography
        className={classes.quote}
        variant="button"
        color="initial"
        component="p"
      >
        {testimony.text.text}
      </Typography>
    </div>
  )
}
