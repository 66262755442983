import React, { useState, useEffect } from "react"

import Slider from "react-slick"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout/layout"

import StoreBadge from "../components/general/storeBadge"
import TestimonialCard from "../components/general/testimonialCard"
import EthicCard from "../components/general/ethicCard"
import Quote from "../components/general/quote"
import JoinBanner from "../components/general/joinBanner"
import RowTitle from "../components/general/rowTitle"
import ArticlesRow from "../components/general/articlesRow"

import circledLogo from "../images/landing/logo-circled.svg"

import eyeSlashed from "../images/landing/ethics/eye-slashed.svg"
import ring from "../images/landing/ethics/ring.svg"
import chatBubble from "../images/landing/ethics/chat-bubble.svg"
import heart from "../images/landing/ethics/heart.svg"

import useWamUrl from "../hooks/useWamUrl"

import useStyles from "./index.style"
import { useTheme } from "@material-ui/core"
import SEO from "../components/seo"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { getImage } from "../helpers"

export const query = graphql`
  query MyQuery($language: String!) {
    landing: allContentfulLanding(filter: { node_locale: { eq: $language } }) {
      nodes {
        seo {
          title
          description
        }
        blogPreview
        callToAction
        coverPicture {
          title
          file {
            url
          }
        }
        testimonies {
          name
          picture {
            title
            file {
              url
            }
          }
          memberSince
          text {
            text
          }
        }
        theyTrustInshallah
        theyTrustInshallahSubtitle {
          theyTrustInshallahSubtitle
        }
        value1
        value1quote {
          value1quote
        }
        value1subtitle {
          value1subtitle
        }
        value2
        value2quote {
          value2quote
        }
        value2subtitle {
          value2subtitle
        }
        value3
        value3quote {
          value3quote
        }
        value3subtitle {
          value3subtitle
        }
        value4
        value4quote {
          value4quote
        }
        value4subtitle {
          value4subtitle
        }
        values
        valuesSubtitle
        whyInshallah
        whyInshallahSubtitle
        whyInshallahSections {
          title
          subtitle {
            json
          }
          quote {
            json
          }
          image {
            title
            file {
              url
            }
          }
        }
        tagline
        subTagline
        joinBannerDescription
        joinBannerTitle
        bannerImage {
          title
          file {
            url
          }
        }
        medias {
          title
          file {
            url
          }
        }
      }
    }
    blogPreview: allContentfulBlogPost(
      limit: 3
      sort: { order: DESC, fields: createdAt }
      filter: {
        category: { category: { name: { eq: "Blog" } } }
        node_locale: { eq: $language }
      }
    ) {
      nodes {
        title
        coverPicture {
          file {
            url
          }
        }
        author {
          name
        }
        category {
          name
          seo {
            slug
          }
        }
        content {
          json
        }
        createdAt(fromNow: true, locale: $language)
        seo {
          slug
        }
      }
    }
  }
`

export default function Home({ data }) {
  const landingData = data?.landing?.nodes[0] || {}
  const blogPreviewData = data?.blogPreview?.nodes || []

  const classes = useStyles({
    landingBackgroundUrl: getImage(landingData.coverPicture.file.url, true),
  })

  const theme = useTheme()
  const { t } = useTranslation()

  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  // const [testimonialsSettings, setTestimonialsSettings] = useState({})

  useEffect(() => {
    setHeight(window.innerHeight)
    setWidth(window.innerWidth)
  }, [])

  const { redirectToWam } = useWamUrl()

  const testimonialsSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: `${width / 4 / 2}px`,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: `${width / 4 / 2}px`,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const logosSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const ethicsSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4500,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Layout animatedNavbar>
      <SEO
        title={landingData.seo.title}
        description={landingData.seo.description}
      />
      <div className={classes.header} style={{ height: "100vh" }}>
        <div className={classes.textWrapper}>
          <Typography variant="h1" color="inherit" align="center">
            {landingData.tagline}
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="h4"
            color="inherit"
            align="center"
          >
            {landingData.subTagline}
          </Typography>
          <div className={classes.logoWrapper}>
            <StoreBadge store="apple" margin={theme.spacing(0, 1.5, 0, 0)} />
            <StoreBadge store="google" margin={theme.spacing(0, 0, 0, 1.5)} />
          </div>

          {landingData.callToAction && (
            <Button
              classes={{
                root: classes.signUpButton,
              }}
              onClick={() => redirectToWam("join")}
              disableRipple
              disableFocusRipple
            >
              {landingData.callToAction}
            </Button>
          )}
        </div>

        <div className={classes.parternsWrapper}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={3}>
              <div className={classes.parternsTitleWrapper}>
                <Typography variant="body1" color="inherit" align="center">
                  {t("landing.talkingAboutUs")}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={9}>
              <Slider className={classes.slider} {...logosSettings}>
                {landingData.medias.map(media => (
                  <img
                    className={classes.partnerLogo}
                    src={getImage(media.file.url)}
                    alt={media.title}
                  />
                ))}
              </Slider>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.fullWidthRow}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className={classes.logoCircled}
              src={circledLogo}
              alt="Inshallah logo circled"
            />
            <Typography
              className={classes.fullWidthColumnTitle}
              style={{ marginTop: theme.spacing(1) }}
              variant="h1"
              component="h2"
              align="center"
            >
              {landingData.whyInshallah}
            </Typography>
            <Typography
              className={classes.fullWidthColumnText}
              variant="button"
              component="p"
              align="center"
            >
              {landingData.whyInshallahSubtitle}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div className={classes.iPhonesContent}>
        <Grid container spacing={2}>
          {landingData.whyInshallahSections.map((whySection, index) => (
            <>
              {index % 2 !== 0 && (
                <Grid className={classes.column} item xs={12} md={6}>
                  {whySection.image && (
                    <img
                      className={classes.iPhone}
                      src={getImage(whySection.image.file.url)}
                      alt={whySection.image.title}
                    />
                  )}
                </Grid>
              )}
              <Hidden smDown={true}>
                <Grid className={classes.textColumn} item xs={12} md={6}>
                  <div className={classes.paragraphBlock}>
                    <div className={classes.paragraphTextWrapper}>
                      <Typography variant="h6" component="h3">
                        {whySection.title}
                      </Typography>
                      {whySection.subtitle && (
                        <Typography
                          className={classes.paragraphText}
                          variant="button"
                          component="p"
                        >
                          {documentToReactComponents(whySection.subtitle.json, {
                            renderNode: {
                              [BLOCKS.HEADING_2]: (_node, children) => (
                                <h2>{children}</h2>
                              ),
                              [BLOCKS.EMBEDDED_ASSET]: node => (
                                <img
                                  src={`${node.data.target.fields.file["en-US"].url}?w=300&q=90`}
                                  alt={node.data.target.fields.title["en-US"]}
                                />
                              ),
                            },
                          })}
                        </Typography>
                      )}
                      {whySection.quote && (
                        <Quote
                          className={classes.iPhoneQuote}
                          quote={documentToReactComponents(
                            whySection.quote.json,
                            {
                              renderNode: {
                                [BLOCKS.HEADING_2]: (_node, children) => (
                                  <h2>{children}</h2>
                                ),
                                [BLOCKS.EMBEDDED_ASSET]: node => (
                                  <img
                                    src={`${node.data.target.fields.file["en-US"].url}?w=300&q=90`}
                                    alt={node.data.target.fields.title["en-US"]}
                                  />
                                ),
                              },
                            }
                          )}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
              </Hidden>
              {index % 2 === 0 && (
                <Grid className={classes.column} item xs={12} md={6}>
                  {whySection.image && (
                    <img
                      className={classes.iPhone}
                      src={getImage(whySection.image.file.url)}
                      alt={whySection.image.title}
                    />
                  )}
                </Grid>
              )}
              <Hidden mdUp>
                <Grid className={classes.textColumn} item xs={12} md={6}>
                  <div className={classes.paragraphBlock}>
                    <div className={classes.paragraphTextWrapper}>
                      <Typography variant="h6" component="h3">
                        {whySection.title}
                      </Typography>
                      {whySection.subtitle && (
                        <Typography
                          className={classes.paragraphText}
                          variant="button"
                          component="p"
                        >
                          {documentToReactComponents(whySection.subtitle.json, {
                            renderNode: {
                              [BLOCKS.HEADING_2]: (_node, children) => (
                                <h2>{children}</h2>
                              ),
                              [BLOCKS.EMBEDDED_ASSET]: node => (
                                <img
                                  src={`${node.data.target.fields.file["en-US"].url}?w=300&q=90`}
                                  alt={node.data.target.fields.title["en-US"]}
                                />
                              ),
                            },
                          })}
                        </Typography>
                      )}
                      {whySection.quote && (
                        <Quote
                          className={classes.iPhonequote}
                          quote={documentToReactComponents(
                            whySection.quote.json,
                            {
                              renderNode: {
                                [BLOCKS.HEADING_2]: (_node, children) => (
                                  <h2>{children}</h2>
                                ),
                                [BLOCKS.EMBEDDED_ASSET]: node => (
                                  <img
                                    src={`${node.data.target.fields.file["en-US"].url}?w=300&q=90`}
                                    alt={node.data.target.fields.title["en-US"]}
                                  />
                                ),
                              },
                            }
                          )}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
              </Hidden>
            </>
          ))}
        </Grid>
      </div>

      <div className={classes.fullWidthRow}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.fullWidthColumnTitle}
              variant="h1"
              component="h2"
              align="center"
            >
              {landingData.values}
            </Typography>
            {landingData.valuesSubtitle && (
              <Typography
                className={classes.fullWidthColumnText}
                variant="button"
                component="p"
                align="center"
              >
                {landingData.valuesSubtitle.valuesSubtitle}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>

      <Hidden smDown>
        <div className={classes.ethicsWrapper}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div className={classes.paragraphBlock}>
                <div className={classes.paragraphTextWrapper}>
                  <EthicCard
                    color="#FFC400"
                    icon={chatBubble}
                    title={landingData.value1}
                    description={landingData.value1subtitle.value1subtitle}
                    quote={landingData.value1quote.value1quote}
                    firstRowItem
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={classes.paragraphBlock}>
                <div className={classes.paragraphTextWrapper}>
                  <EthicCard
                    color="#FF5630"
                    icon={heart}
                    title={landingData.value2}
                    description={landingData.value2subtitle.value2subtitle}
                    quote={landingData.value2quote.value2quote}
                    firstRowItem
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={classes.paragraphBlock}>
                <div className={classes.paragraphTextWrapper}>
                  <EthicCard
                    color="#0065FF"
                    icon={eyeSlashed}
                    title={landingData.value3}
                    description={landingData.value3subtitle.value3subtitle}
                    quote={landingData.value3quote.value3quote}
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={classes.paragraphBlock}>
                <div className={classes.paragraphTextWrapper}>
                  <EthicCard
                    color="#36B37E"
                    icon={ring}
                    title={landingData.value4}
                    description={landingData.value4subtitle.value4subtitle}
                    quote={landingData.value4quote.value4quote}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Hidden>

      <Hidden mdUp>
        <div className={classes.ethicsWrapper}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Slider className={classes.slider} {...ethicsSettings}>
                <EthicCard
                  color="#FFC400"
                  icon={chatBubble}
                  title={landingData.value1}
                  description={landingData.value1subtitle.value1subtitle}
                  quote={landingData.value1quote.value1quote}
                />
                <EthicCard
                  color="#FF5630"
                  icon={heart}
                  title={landingData.value2}
                  description={landingData.value2subtitle.value2subtitle}
                  quote={landingData.value2quote.value2quote}
                />
                <EthicCard
                  color="#0065FF"
                  icon={eyeSlashed}
                  title={landingData.value3}
                  description={landingData.value3subtitle.value3subtitle}
                  quote={landingData.value3quote.value3quote}
                />
                <EthicCard
                  color="#36B37E"
                  icon={ring}
                  title={landingData.value4}
                  description={landingData.value4subtitle.value4subtitle}
                  quote={landingData.value4quote.value4quote}
                />
              </Slider>
            </Grid>
          </Grid>
        </div>
      </Hidden>

      <div className={classes.fullWidthRow}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.fullWidthColumnTitle}
              variant="h1"
              component="h2"
              align="center"
            >
              {landingData.theyTrustInshallah}
            </Typography>
            {landingData.theyTrustInshallahSubtitle && (
              <Typography
                className={classes.fullWidthColumnText}
                variant="button"
                component="p"
                align="center"
              >
                {
                  landingData.theyTrustInshallahSubtitle
                    .theyTrustInshallahSubtitle
                }
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>

      <div className={classes.testimonialsWrapper}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Slider className={classes.slider} {...testimonialsSettings}>
              {landingData.testimonies.map((testimony, index) => (
                <TestimonialCard key={index} testimony={testimony} />
              ))}
            </Slider>
          </Grid>
        </Grid>
      </div>

      <div className={classes.joinBannerWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <JoinBanner
              title={landingData.joinBannerTitle}
              description={landingData.joinBannerDescription}
              image={landingData.bannerImage}
            />
          </Grid>
        </Grid>
      </div>

      <RowTitle title={landingData.blogPreview} href="/blog" />
      <ArticlesRow
        data={blogPreviewData.map(post => ({
          ...post,
          href: `/blog/${post.category.seo.slug}/${post.seo.slug}`,
        }))}
      />
    </Layout>
  )
}
