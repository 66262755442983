import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: props => props.size,
    height: props => props.size,
    minWidth: props => props.size,
    minHeight: props => props.size,
    margin: props => props.margin,

    "&:hover": {
      cursor: props => (props.onClick ? "pointer" : "inherit"),
    },
  },
  picture: {
    width: "100%",
    height: "100%",
    borderRadius: props => props.size / 2,
    objectFit: "cover",
  },
  border: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: `2px solid rgba(0, 0, 0, 0.19)`,
    borderRadius: "50%",
  },
}))

export default useStyles
