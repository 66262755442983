import React from "react"

import ArticleItem from "./articleItem"

import useStyles from "./articlesRow.style"

function ArticleRow({ data }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.spacer} />

      {data &&
        data.map((item, index) => {
          return (
            <>
              {index !== 1 && <div className={classes.cardSpacer} />}
              <ArticleItem data={item} />
              {index !== 1 && <div className={classes.cardSpacer} />}
            </>
          )
        })}
    </div>
  )
}

export default ArticleRow
