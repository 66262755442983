import React from "react"

import Typography from "@material-ui/core/Typography"

import Quote from "./quote"

import useStyles from "./ethicCard.style"
import clsx from "clsx"

export default function Button({
  color,
  title,
  description,
  quote,
  icon,
  iconAlt,
  firstRowItem,
}) {
  const classes = useStyles()

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.firstRowRoot]: firstRowItem,
  })

  return (
    <div className={rootClasses} style={{ color: color }}>
      <img className={classes.ethicBubble} src={icon} alt={iconAlt} />
      <Typography
        className={classes.quote}
        variant="h5"
        color="inherit"
        component="h4"
      >
        {title}
      </Typography>
      <Typography
        className={classes.quote}
        variant="button"
        color="initial"
        component="p"
      >
        {description}
      </Typography>

      <Quote className={classes.quote} quote={quote} />
    </div>
  )
}
