import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    overflowX: "scroll",
    overflowY: "hidden",
    maxWidth: "1080px",
    margin: "0 auto",
    paddingBottom: 40,

    "&::-webkit-scrollbar": {
      height: 0,
      width: 0,
      background: "transparent",
    },
  },
  sideSpacer: {
    display: "none",

    "@media screen and (min-width: 425px)": {
      display: "block",
      minWidth: "16px",
    },

    "@media screen and (min-width: 1108px)": {
      display: "none",
      minWidth: 0,
    },
  },
  cardSpacer: {
    minWidth: "16px",

    "@media screen and (min-width: 425px)": {
      // min-width: ${({ hide }) => (hide ? "0" : "40px")};
    },
  },
}))

export default useStyles
