import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flex: 0,
    overflow: "hidden",
    minWidth: "324px",
    minHeight: "512px",
    maxWidth: "324px",
    maxHeight: "512px",
    borderRadius: "8px",
    border: "1px solid #EBEBEB",
    boxSizing: "border-box",
    "-moz-box-sizing": "border-box",
    "-webkit-box-sizing": "border-box",
    boxShadow: theme.shadows[0],
    transition: "all 250ms",

    "@media screen and (min-width: 425px)": {
      flex: 1,
      minWidth: "333px",
      minHeight: "517px",
      maxWidth: "333px",
      maxHeight: "517px",
    },

    "&:hover": {
      textDecoration: "initial",
      boxShadow: theme.shadows[2],
      transition: "all 250ms",
    },
  },
  picture: {
    width: "100%",
    height: "193px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
  category: {
    fontWeight: 600,
    color: "#FFC400",
    textTransform: "uppercase",
    margin: theme.spacing(0, 3),
    marginTop: theme.spacing(3),
  },
  title: {
    margin: theme.spacing(0, 3),
  },
  subtitle: {
    fontSize: 20,
    lineHeight: "20px",
    marginLeft: theme.spacing(0.25),
  },
  textWrapper: {
    flexGrow: 1,
    position: "relative",
    overflow: "hidden",
  },
  text: {
    margin: theme.spacing(0, 3),
    marginTop: theme.spacing(1),
    lineHeight: "26px",
    textAlign: "justify",
  },
  textOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg,rgba(2, 0, 36, 0) 0%,rgba(255, 255, 255, 100) 95%)",
  },
  authorWrapper: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(3),
    alignItems: "center",
  },
  auhorName: {
    flexGrow: 1,
    color: "#454F5B",
    marginLeft: theme.spacing(1.5),
  },
  readingTime: {
    fontWeight: 600,
    color: "#FFC400",
    textTransform: "uppercase",
  },
}))

export default useStyles
