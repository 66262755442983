import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3),

    [theme.breakpoints.up("md")]: {
      marginTop: 80,
    },
  },
  firstRowRoot: {
    marginTop: 0,
  },
  quote: {
    marginTop: theme.spacing(2),
  },
  ethicBubble: {
    width: 48,
    height: 48,
  },
}))

export default useStyles
