import React from "react"
import { getImage } from "../../helpers"

import useStyles from "./circledPicture.style"

function CircledPicture({
  picture,
  bordered,
  thickBorder,
  unseen,
  className,
  yellowBorder,
  isPremium,
  settings,
  pictureId,
  ...props
}) {
  const classes = useStyles(props)

  return (
    <div className={`${classes.root} ${className}`}>
      <img
        className={classes.picture}
        src={getImage(picture.file.url)}
        alt={picture.title}
        id={pictureId}
      />
      {bordered && <div className={classes.border} />}
    </div>
  )
}

CircledPicture.defaultProps = {
  size: 56,
  picture:
    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
}

export default CircledPicture
