import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#FFF",
    border: "1px solid rgba(33, 43, 54, 0.25)",
    boxShadow: "0px 2px 2px rgba(33, 43, 54, 0.31)",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    margin: theme.spacing(0, 1.5),
  },
  header: {
    display: "flex",
  },
  picture: {
    marginRight: theme.spacing(2),
  },
  quote: {
    lineHeight: "26px",
    marginTop: theme.spacing(2),
  },
}))

export default useStyles
